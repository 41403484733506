<template>
	<div>

		<LandingpageHeader></LandingpageHeader>


		<div class="app-download">
			<img src="@/assets/landingpage/app-download.png" srcset="@/assets/landingpage/app-download@2x.png 2x" alt="Download SportlerPlus App">
			<h1>App herunterladen</h1>
			<p>Um auf dieses <span>Workout</span> zugreifen zu können, benötigst du die SportlerPlus-App. Einfach herunterladen & schon geht’s los!</p>
			<div class="header__buttons">
				<a href="https://apps.apple.com/de/app/sportlerplus/id1533773413" target="_blank" class="download__button">
					<div class="download__button__icon">
						<img src="@/assets/ic_appstore_apple.svg" alt="Download Apple AppStore" />
					</div>
					<div class="download__button__text">
						Download on the
						<span>AppStore</span>
					</div>
				</a>
				<a href="https://play.google.com/store/apps/details?id=com.sportplatzmedia.sportlerplus" target="_blank" class="download__button">
					<div class="download__button__icon">
						<img src="@/assets/ic_appstore_google.svg" alt="Download GooglePlay" />
					</div>
					<div class="download__button__text">
						Get it on
						<span>GooglePlay</span>
					</div>
				</a>
			</div>
		</div>
				

		<LandingpageFooter></LandingpageFooter>

	</div>
</template>

<script>
	import LandingpageHeader from "@/components/LandingpageHeader.vue";
	import LandingpageFooter from "@/components/LandingpageFooter.vue";

	export default {
		name: 'LandingpageAGB',
		components: { LandingpageHeader, LandingpageFooter },
	}

</script>

<style lang="scss">
	.app-download {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		padding: 80px 16px 160px;

		h1 {
			font-size: 24px;
			line-height: 32px;
			letter-spacing: -0.35px;
			color: $colorBlack;
			margin: 14px 0 11px;
		}

		p {
			opacity: 1;
			color: rgba($colorBlack, .64);
			font-weight: 600;
			text-align: center;
			line-height: 20px;
			margin-bottom: 27px;

			span {
				color: $colorBlack;
			}
		}

		.header__buttons {
			/* width: 100%; */
			margin: auto;
			display: flex
		}

		.header__buttons a {
			margin: 8px;
			max-width: 220px
		}
	}
</style>